exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-dashboard-tsx": () => import("./../../../src/pages/app/dashboard.tsx" /* webpackChunkName: "component---src-pages-app-dashboard-tsx" */),
  "component---src-pages-app-forgot-password-tsx": () => import("./../../../src/pages/app/forgotPassword.tsx" /* webpackChunkName: "component---src-pages-app-forgot-password-tsx" */),
  "component---src-pages-app-login-tsx": () => import("./../../../src/pages/app/login.tsx" /* webpackChunkName: "component---src-pages-app-login-tsx" */),
  "component---src-pages-app-place-order-tsx": () => import("./../../../src/pages/app/place-order.tsx" /* webpackChunkName: "component---src-pages-app-place-order-tsx" */),
  "component---src-pages-app-profile-tsx": () => import("./../../../src/pages/app/profile.tsx" /* webpackChunkName: "component---src-pages-app-profile-tsx" */),
  "component---src-pages-app-register-tsx": () => import("./../../../src/pages/app/register.tsx" /* webpackChunkName: "component---src-pages-app-register-tsx" */),
  "component---src-pages-app-simulator-tsx": () => import("./../../../src/pages/app/simulator.tsx" /* webpackChunkName: "component---src-pages-app-simulator-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-bonplan-tsx": () => import("./../../../src/pages/bonplan.tsx" /* webpackChunkName: "component---src-pages-bonplan-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-tracking-tsx": () => import("./../../../src/pages/tracking.tsx" /* webpackChunkName: "component---src-pages-tracking-tsx" */),
  "component---src-pages-wari-tsx": () => import("./../../../src/pages/wari.tsx" /* webpackChunkName: "component---src-pages-wari-tsx" */)
}

